import React from "react"
import { graphql, PageProps } from "gatsby"

import { useI18n, LocaleLink as Link } from "../contexts/i18n"
import Layout from "../layout"
import SEO from "../components/SEO"
import List from "../components/List"
import Textblock from "../components/Textblock"
import * as Routes from "../../routes"

interface DataType {
  pressReleases: Contentful.Cxn<Contentful.PressRelease>
}

const MediaPage = ({ data }: PageProps<DataType>) => {
  const { translate: t } = useI18n(`mediaPage`)

  return (
    <Layout narrow>
      <Textblock>
        <SEO title="Media" />
        <h1>{t`Media`}</h1>
        <h2>{t`Press Releases`}</h2>
        <List className="press-release-list">
          {data.pressReleases.edges.map((pressRelease) => (
            <Link
              to={Routes.pressReleasePath(pressRelease.node.slug)}
              title={pressRelease.node.title}
              className="press-release-card-link"
            >
              <div className="press-release-card">
                <h3 className="press-release-title">{pressRelease.node.title}</h3>
                <time>{pressRelease.node.date}</time>
                <p>{pressRelease.node.description}</p>
              </div>
            </Link>
          ))}
        </List>
      </Textblock>
    </Layout>
  )
}

export default MediaPage

export const pageQuery = graphql`
  query ($locale: String!) {
    pressReleases: allContentfulPressRelease(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          date
          description
          slug
        }
      }
    }
  }
`
